import { render, staticRenderFns } from "./VisitCustomersCard.vue?vue&type=template&id=295aab70"
import script from "./VisitCustomersCard.vue?vue&type=script&lang=js"
export * from "./VisitCustomersCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports