var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"title":"Cartões analíticos","breadcrumbTitle":true,"breadcrumb":[
      {
        title: 'Main',
      },
      {
        title: 'Widgets',
      },
      {
        title: 'Cards',
      },
    ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Você pode encontrar cartões analíticos especiais que criamos para o modelo Admin ")])]},proxy:true}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"8"}},[_c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('revenue-column-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('visiters-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('trafic-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('marketplace-card')],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('visit-customers-card')],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('expenses-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('revenue-radar-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('revenue-line-card')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('energy-card')],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }